<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="동력기계 상세정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      (주)
                    </div>
                    <div class="tooltipCustom">
                    ① 방호‧보호장치의 종류에는 법적인 안전/방호장치와 모터보호장치(THT＼R, EOCR, EMPR 등) 등을 기재합니다.<br>
                    ② 비고에는 인버터 또는 기동방식 등을 기재합니다.
                    </div>
                  </q-tooltip>
                </q-btn>
                <c-btn
                  v-show="(editable && popupParam.psiPowerEquipmentId && !isRevision) && isEnalbed"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="(editable && popupParam.psiPowerEquipmentId && isRevision) && isEnalbed"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-if="editable && popupParam.psiPowerEquipmentId && isEnalbed"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="동력기계번호"
                  name="psiPowerEquipmentNo"
                  v-model="data.psiPowerEquipmentNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="동력기계명"
                  name="powerMachineName"
                  v-model="data.powerMachineName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-equip
                  :editable="editable"
                  label="설비"
                  name="equipmentCd"
                  v-model="data.equipmentCd">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  label="명세"
                  name="specInfo"
                  :rows="3"
                  v-model="data.specInfo">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  label="주요재질"
                  name="matQuality"
                  :rows="3"
                  v-model="data.matQuality">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  label="방호.보호장치의 종류"
                  name="protectType"
                  :rows="3"
                  v-model="data.protectType">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  label="비고"
                  name="remarks"
                  :rows="3"
                  v-model="data.remarks">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  suffix="KW"
                  :editable="editable"
                  label="전동기용량(KW)"
                  name="motorVol"
                  v-model="data.motorVol">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
          <c-card title="개정" class="cardClassDetailForm" topClass="topcolor-orange" v-if="popupParam.psiPowerEquipmentId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <c-moc
                      :editable="editable && isRevision"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="popupParam.psiPowerEquipmentId"
                      documentTitle="powerMachineName"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'power-machine-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiPowerEquipmentId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveUrl: transactionConfig.psi.pfi.power.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      isEnalbed: true,
      data: {
        plantCd: '',  // 사업장코드
        psiPowerEquipmentId: '',  // 동력기계일련번호
        psiPowerEquipmentNo: '',  // 동력기계번호
        powerMachineName: '',  // 동력기계명
        equipmentCd: '',  // 설비코드
        specInfo: '',  // 명세
        matQuality: '',  // 주요재질
        motorVol: '',  // 전동기용량(KW)
        protectType: '',  // 방호장치종류
        remarks: '',  // 비고
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      saveCallData: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.popupParam.popeditable === undefined) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else {
        this.editable = false;
        this.isEnalbed = false;
      }
      // url setting
      this.detailUrl = selectConfig.psi.pfi.power.get.url;
      this.insertUrl = transactionConfig.psi.pfi.power.insert.url;
      this.updateUrl = transactionConfig.psi.pfi.power.update.url;
      this.deleteUrl = transactionConfig.psi.pfi.power.delete.url;
      this.revlistUrl = selectConfig.psi.pfi.power.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiPowerEquipmentId) {
        this.getDetail(this.popupParam.psiPowerEquipmentId, true);
      }
    },
    getDetail(_psiPowerEquipmentId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiPowerEquipmentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiPowerEquipmentId == this.popupParam.psiPowerEquipmentId && this.isEnalbed) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiPowerEquipmentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiPowerEquipmentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiPowerEquipmentId = result.data.psiPowerEquipmentId
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row.psiPowerEquipmentId != this.data.psiPowerEquipmentId) {
        this.getDetail(row.psiPowerEquipmentId, false);
      }
    },
  }
};
</script>
